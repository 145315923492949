import {
  WppAccordion,
  WppActionButton,
  WppButton,
  WppCard,
  WppIconAdd,
  WppTypography,
} from '@platform-ui-kit/components-library-react'
import { RowDragEndEvent } from 'ag-grid-community'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useUpdateAttributesApi } from 'api/projects/mutation/useUpdateAttributesApi'
import { useProjectAttributesApi } from 'api/projects/queries/useProjectAttributesApi'
import { Flex } from 'components/common/flex/Flex'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { useToast } from 'hooks/useToast'
import { showAttributeModal } from 'pages/settings/attributes/components/addAttributeModal/AttributeModal'
import { AttributesTable } from 'pages/settings/attributes/components/attributesTable/AttributesTable'
import styles from 'pages/settings/Settings.module.scss'
import { queryClient } from 'providers/osQueryClient/utils'
import { ProjectAttribute, ProjectAttributeClass } from 'types/projects/attributes'

interface CollapseState {
  custom: boolean
  system: boolean
}

export const AttributesTab = () => {
  const { t } = useTranslation()
  const { showToast } = useToast()
  const { data: attributes, isLoading: isAttributesLoading } = useProjectAttributesApi()
  const { mutateAsync: handleUpdateAttributes } = useUpdateAttributesApi()
  const [accordionState, setAccordionState] = useState<CollapseState>({ custom: false, system: false })

  const { system, custom } = useMemo(
    () =>
      attributes?.reduce(
        (acc, attribute) => {
          if (attribute.classification === ProjectAttributeClass.SYSTEM) {
            acc.system.push(attribute)
          } else {
            acc.custom.push(attribute)
          }

          return acc
        },
        { system: [], custom: [] } as { system: ProjectAttribute[]; custom: ProjectAttribute[] },
      ),
    [attributes],
  )

  const handleCollapse = (state: Partial<CollapseState>) => setAccordionState({ ...accordionState, ...state })

  const handleRowDrag = async ({ node: { data }, overIndex }: RowDragEndEvent) => {
    try {
      await handleUpdateAttributes({ id: data.id, data: { orderNumber: custom[overIndex].orderNumber } })
      showToast({ type: 'success', message: t('project.settings.attributes.attributes_reordered') })
    } catch (error) {
      showToast({ type: 'error', message: t('common.generic_error') })
      console.log(error)
    } finally {
      queryClient.invalidateQueries([ApiQueryKeys.PROJECT_ATTRIBUTES_BLUEPRINT])
    }
  }

  return (
    <WppCard variant="primary" size="l" className="wpp-spacing-24-top">
      <Flex direction="column">
        <WppTypography type="l-strong">{t('project.settings.attributes.title')}</WppTypography>
        <WppTypography type="s-body" className={styles.attributesDescription}>
          {t('project.settings.attributes.subtitle')}
        </WppTypography>

        <WppCard variant="secondary" className={styles.systemCard}>
          <WppAccordion
            size="m"
            withDivider={false}
            expandedByDefault={false}
            expanded={accordionState.system}
            onWppChange={e => handleCollapse({ system: e.detail.expanded })}
          >
            <WppTypography type="m-strong" slot="header" data-testid="system-attributes-title">
              {t('project.settings.attributes.system')}
            </WppTypography>

            <AttributesTable attributes={system} isLoading={isAttributesLoading} type={ProjectAttributeClass.SYSTEM} />
          </WppAccordion>
        </WppCard>

        <WppCard variant="secondary" className={styles.systemCard}>
          <WppAccordion
            size="m"
            withDivider={false}
            expandedByDefault={false}
            expanded={accordionState.custom}
            onWppChange={e => handleCollapse({ custom: e.detail.expanded })}
          >
            <WppTypography type="m-strong" slot="header">
              {t('project.settings.attributes.custom')}
            </WppTypography>
            {!!custom.length && (
              <WppActionButton slot="actions" onClick={() => showAttributeModal()}>
                <WppIconAdd slot="icon-start" />
                {t('project.settings.add_attribute')}
              </WppActionButton>
            )}

            {!custom.length && (
              <Flex
                direction="column"
                justify="center"
                align="center"
                style={{ width: '100%', padding: '20px' }}
                gap={24}
              >
                <Flex direction="column" justify="center" align="center" gap={8}>
                  <WppTypography type="m-strong" slot="header">
                    {t('project.settings.no_custom_attributes')}
                  </WppTypography>
                  <WppTypography type="s-body" slot="header">
                    {t('project.settings.add_first_attribute')}
                  </WppTypography>
                </Flex>
                <WppButton variant="secondary" onClick={() => showAttributeModal()}>
                  <WppIconAdd slot="icon-start" />
                  {t('project.settings.add_attribute')}
                </WppButton>
              </Flex>
            )}

            {!!custom.length && (
              <AttributesTable
                attributes={custom}
                isLoading={isAttributesLoading}
                type={ProjectAttributeClass.CUSTOM}
                withActions={true}
                onRowDragEnd={handleRowDrag}
              />
            )}
          </WppAccordion>
        </WppCard>
      </Flex>
    </WppCard>
  )
}
